import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuth } from './AuthContext.v3';
import './showMenuItems.v1.css';

const ShowMenuItems = () => {
  const [menuData, setMenuData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedMenuType, setSelectedMenuType] = useState('');
  const [selectedMenuItems, setSelectedMenuItems] = useState([]);
  const { token } = useAuth();

  // Fetch menu data
  useEffect(() => {
    const fetchMenuData = async () => {
      setLoading(true);
      setError(null);
      try {
        const menuResponse = await fetch('https://api.reshipesbakery.com/reshipes/getMenuTypes', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        
        if (!menuResponse.ok) {
          throw new Error('Failed to fetch menu types');
        }
        
        const menuTypes = await menuResponse.json();
        
        const menuItemsPromises = menuTypes.map(async (menuType) => {
          const itemsResponse = await fetch(`https://api.reshipesbakery.com/item/getItemsWithSpecificMenu?menuName=${menuType}`, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          });
          
          if (!itemsResponse.ok) {
            return { menuType, items: [] };
          }
          
          const items = await itemsResponse.json();
          return { menuType, items };
        });
        
        const menuItems = await Promise.all(menuItemsPromises);
        setMenuData(menuItems);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMenuData();
  }, [token]);

  const handleMenuTypeChange = (e) => {
    const menuType = e.target.value;
    setSelectedMenuType(menuType);

    const selected = menuData.find(menu => menu.menuType === menuType);
    setSelectedMenuItems(selected ? selected.items : []);
  };

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Menu Items</h1>
      {loading && <p>Loading...</p>}
      {error && <div className="alert alert-danger">{error}</div>}

      {/* Menu Type Dropdown */}
      <div className="form-group">
        <label htmlFor="menuTypeSelect">Select Menu Type:</label>
        <select
          className="form-control"
          id="menuTypeSelect"
          value={selectedMenuType}
          onChange={handleMenuTypeChange}
        >
          <option value="">Select a menu type</option>
          {menuData.map(({ menuType }) => (
            <option key={menuType} value={menuType}>
              {menuType}
            </option>
          ))}
        </select>
      </div>

      {/* Display Selected Menu Items */}
      {selectedMenuType && (
        <div className="mt-4">
          <h3>Items for {selectedMenuType}</h3>
          {selectedMenuItems.length === 0 ? (
            <p>No items available</p>
          ) : (
            <ul className="list-group">
              {selectedMenuItems.map(item => (
                <li key={item.id} className="list-group-item">
                  {item.size} {item.foodName} - ${item.price}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default ShowMenuItems;
