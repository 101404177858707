import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext.v3';

const AddItem = () => {
  const [item, setItem] = useState({
    price: '',
    size: '',
    foodName: '',
    category: '',
    menuType: ''
  });
  const [menuTypes, setMenuTypes] = useState([]);
  const [categories, setCategories] = useState([]); // State for categories
  const [newMenu, setNewMenu] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const { token } = useAuth();

  // Fetch categories
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://api.reshipesbakery.com/reshipes/getCategoryTypes', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch categories');
        }

        const data = await response.json();
        setCategories(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchCategories();
  }, [token]);

  // Fetch menu types
  useEffect(() => {
    const fetchMenuTypes = async () => {
      try {
        const response = await fetch('https://api.reshipesbakery.com/reshipes/getMenuTypes', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch menu types');
        }
        const data = await response.json();
        setMenuTypes(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchMenuTypes();
  }, [token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setItem({
      ...item,
      [name]: value
    });
  };

  const handleNewMenuChange = (e) => {
    setNewMenu(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    const itemToSubmit = {
      ...item,
      price: parseFloat(item.price)
    };

    // If creating a new menu, set the menuType to the new menu name
    if (item.menuType === 'new') {
      itemToSubmit.menuType = newMenu;
    }

    console.log("Posting the following JSON:", JSON.stringify(itemToSubmit));

    try {
      const response = await fetch('https://api.reshipesbakery.com/item/add', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(itemToSubmit)
      });

      if (!response.ok) {
        if (response.status === 409) {
          throw new Error("This item already exists on this menu. Please try a different one.");
        }
        throw new Error("Something went wrong. Please try again.");
      }
    
      setSuccess(true);
      setItem({
        price: '',
        size: '',
        foodName: '',
        category: '',
        menuType: '' // reset to default value
      });
      setNewMenu(''); // reset new menu field
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <h1>Add Item to Menu</h1>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="form-group">
          <label htmlFor="foodName">Food Name</label>
          <input
            type="text"
            className="form-control"
            id="foodName"
            name="foodName"
            value={item.foodName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="size">Size (Optional)</label>
          <select
            className="form-control"
            id="size"
            name="size"
            value={item.size}
            onChange={handleChange}
          >
            <option value="">Select Size</option>
            <option value="Small">Small</option>
            <option value="Medium">Medium</option>
            <option value="Large">Large</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="price">Price (Don't type a $ just write the number)</label>
          <input
            type="number"
            step="0.01"
            className="form-control"
            id="price"
            name="price"
            value={item.price}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="category">Category</label>
          <select
            className="form-control"
            id="category"
            name="category"
            value={item.category}
            onChange={handleChange}
            required
          >
            <option value="">Select Category</option>
            {categories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="menuType">Menu Type</label>
          <select
            className="form-control"
            id="menuType"
            name="menuType"
            value={item.menuType}
            onChange={handleChange}
            required
          >
            <option value="">Select Menu Type</option>
            {menuTypes.map((menuType, index) => (
              <option key={index} value={menuType}>
                {menuType}
              </option>
            ))}
            <option value="new">Create New Menu</option>
          </select>
        </div>
        {item.menuType === 'new' && (
          <div className="form-group">
            <label htmlFor="newMenu">New Menu Name</label>
            <input
              type="text"
              className="form-control"
              id="newMenu"
              name="newMenu"
              value={newMenu}
              onChange={handleNewMenuChange}
              required
            />
          </div>
        )}
        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? 'Adding...' : 'Add Item'}
        </button>
      </form>
      {error && <div className="mt-3 alert alert-danger">{error}</div>}
      {success && <div className="mt-3 alert alert-success">Item added successfully!</div>}
    </div>
  );
};

export default AddItem;
